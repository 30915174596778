<template>
    <v-card flat>
        <v-card-title>
            <div class="primary--text">
                <div class="d-flex align-center">
                    <span class="font-weight-bold">Tasks</span>
                    <v-btn :loading="fetchingUserTasks" icon @click="$store.dispatch('userStore/fetchUserTasks')"><v-icon>refresh</v-icon></v-btn>
                </div>
                <span v-if="fetchingUserTasks" class="caption text-uppercase">fetching tasks data...</span>
            </div>
        </v-card-title>
        <v-card-text>
            <div class="d-flex align-center flex-wrap">
                <div v-for="taskType in availableTaskTypes" :key="taskType">
                    <v-checkbox class="mx-4" v-model="taskCheckBoxData[taskType]">
                        <template v-slot:label>
                            <span class="primary--text caption text-uppercase">{{ taskNameMappings[taskType] }}</span>
                        </template>
                    </v-checkbox>
                </div>
            </div>
            <div>
                <v-data-table
                    :footer-props="{ itemsPerPageOptions }"
                    :items-per-page="100"
                    :search="search"
                    :loading="fetchingUserTasks"
                    :headers="headers"
                    :items="filteredTaskData"
                    item-key="tkid"
                >
                    <template v-slot:top>
                        <v-text-field class="mb-5" v-model="search" append-icon="mdi-magnify" label="Filter" single-line hide-details></v-text-field>
                    </template>
                    <template v-slot:item.status="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" :color="getTaskChipColor(item.status)" left>
                                    {{ getTaskChipIcon(item.status) }}
                                </v-icon>
                            </template>
                            <span class="white--text">{{ taskStatusNames[item.status] }}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:item.operation="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <span class="text-truncate" v-on="on"> {{ truncateText(item.operation, 60) }}</span>
                            </template>
                            <span>{{ item.operation }}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:item.created="{ item }">
                        <div class="d-flex flex-column py-3">
                            {{ item.created | dateTimeToHuman }}
                            <span class="caption" v-if="item.finished">
                                <span class="font-weight-bold">Duration: </span>{{ calculateDuration(item.finished, item.started) }}
                            </span>
                        </div>
                    </template>
                    <template v-slot:item.result="{ item }">
                        <UserTaskOutcome :message="item.result"></UserTaskOutcome>
                    </template>
                </v-data-table>
            </div>
            <v-banner v-if="fetchingUserTasks === false && userTasks.length === 0" two-line>
                <v-avatar slot="icon" color="white" size="60">
                    <v-icon x-large icon="info" color="info">
                        info
                    </v-icon>
                </v-avatar>
                <div style="max-width:900px" class="d-flex flex-column">
                    <span class="font-weight-bold">No user tasks found</span>
                </div>
            </v-banner>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { enumsData } from '@/mixins/enums'
import { calculateDuration, truncateText } from '@/utils'
const UserTaskOutcome = () => import('../components/TheUserTaskOutcome')
export default {
    mixins: [enumsData],
    components: { UserTaskOutcome },
    data() {
        return {
            search: '',
            itemsPerPageOptions: [-1, 100],
            headers: [
                { text: 'Status', align: 'center', value: 'status', width: '80px' },
                {
                    text: 'Task',
                    value: 'operation',
                    width: '300px'
                },
                { text: 'Start time', value: 'created', width: '200px' },
                { text: 'Outcome', value: 'result', width: '50px', align: 'end' }
            ],
            taskStatusNames: {
                0: 'REJECTED',
                1: 'CREATED',
                2: 'QUEUED',
                3: 'RUNNING',
                4: 'COMPLETED',
                5: 'FAILED'
            },
            taskCheckBoxData: {
                COMPLETED: true,
                QUEUED: true,
                REJECTED: true,
                FAILED: true,
                CREATED: true,
                RUNNING: true
            },
            taskNameMappings: {
                COMPLETED: 'COMPLETED',
                QUEUED: 'QUEUED',
                REJECTED: 'REJECTED',
                FAILED: 'FAILED',
                CREATED: 'CREATED',
                RUNNING: 'RUNNING'
            }
        }
    },
    computed: {
        ...mapState('userStore', ['userTasks', 'fetchingUserTasks']),
        availableTaskTypes: function() {
            var taskTypes = []
            if (this.userTasks && this.userTasks.length) {
                this.userTasks.forEach(task => {
                    const taskType = this.$data.taskStatusNames[task.status]
                    if (taskTypes.includes(taskType) === false) {
                        taskTypes.push(taskType)
                    }
                })
                return taskTypes
            }
            return []
        },
        filteredTaskData: function() {
            var taskTypes = []
            if (this.$data.taskCheckBoxData.COMPLETED === true) {
                taskTypes.push(this.$data.taskStatusTypes.COMPLETED)
            }
            if (this.$data.taskCheckBoxData.QUEUED === true) {
                taskTypes.push(this.$data.taskStatusTypes.QUEUED)
            }
            if (this.$data.taskCheckBoxData.REJECTED === true) {
                taskTypes.push(this.$data.taskStatusTypes.REJECTED)
            }
            if (this.$data.taskCheckBoxData.FAILED === true) {
                taskTypes.push(this.$data.taskStatusTypes.FAILED)
            }
            if (this.$data.taskCheckBoxData.CREATED === true) {
                taskTypes.push(this.$data.taskStatusTypes.CREATED)
            }
            if (this.$data.taskCheckBoxData.RUNNING === true) {
                taskTypes.push(this.$data.taskStatusTypes.RUNNING)
            }
            if (taskTypes) {
                const filteredData = this.userTasks.filter(task => taskTypes.includes(task.status))
                return filteredData
            } else {
                return this.userTasks
            }
        }
    },
    methods: {
        calculateDuration: function(date2, date1) {
            return calculateDuration(date2, date1)
        },
        truncateText: function(text, length) {
            return truncateText(text, length)
        },
        getTaskChipColor: function(status) {
            if (status && status === this.$data.taskStatusTypes.COMPLETED) {
                return 'success'
            } else if (status && status === this.$data.taskStatusTypes.REJECTED) {
                return 'warning'
            } else if (status && status === this.$data.taskStatusTypes.QUEUED) {
                return 'brown'
            } else if (status && status === this.$data.taskStatusTypes.FAILED) {
                return 'error'
            } else if (status && status === this.$data.taskStatusTypes.RUNNING) {
                return 'info'
            } else if (status && status === this.$data.taskStatusTypes.CREATED) {
                return 'orange'
            } else {
                return 'info'
            }
        },
        getTaskChipIcon: function(status) {
            if (status && status === this.$data.taskStatusTypes.COMPLETED) {
                return 'done'
            } else if (status && status === this.$data.taskStatusTypes.REJECTED) {
                return 'error_outline'
            } else if (status && status === this.$data.taskStatusTypes.QUEUED) {
                return 'pause'
            } else if (status && status === this.$data.taskStatusTypes.FAILED) {
                return 'error'
            } else if (status && status === this.$data.taskStatusTypes.RUNNING) {
                return 'mdi-timer-sand'
            } else if (status && status === this.$data.taskStatusTypes.CREATED) {
                return 'alarm_on'
            } else {
                return 'info'
            }
        }
    },
    mounted() {
        this.$store.dispatch('userStore/fetchUserTasks')
    }
}
</script>
