var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _c("div", { staticClass: "primary--text" }, [
          _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v("Tasks")
              ]),
              _c(
                "v-btn",
                {
                  attrs: { loading: _vm.fetchingUserTasks, icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$store.dispatch("userStore/fetchUserTasks")
                    }
                  }
                },
                [_c("v-icon", [_vm._v("refresh")])],
                1
              )
            ],
            1
          ),
          _vm.fetchingUserTasks
            ? _c("span", { staticClass: "caption text-uppercase" }, [
                _vm._v("fetching tasks data...")
              ])
            : _vm._e()
        ])
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "div",
            { staticClass: "d-flex align-center flex-wrap" },
            _vm._l(_vm.availableTaskTypes, function(taskType) {
              return _c(
                "div",
                { key: taskType },
                [
                  _c("v-checkbox", {
                    staticClass: "mx-4",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "label",
                          fn: function() {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "primary--text caption text-uppercase"
                                },
                                [_vm._v(_vm._s(_vm.taskNameMappings[taskType]))]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.taskCheckBoxData[taskType],
                      callback: function($$v) {
                        _vm.$set(_vm.taskCheckBoxData, taskType, $$v)
                      },
                      expression: "taskCheckBoxData[taskType]"
                    }
                  })
                ],
                1
              )
            }),
            0
          ),
          _c(
            "div",
            [
              _c("v-data-table", {
                attrs: {
                  "footer-props": {
                    itemsPerPageOptions: _vm.itemsPerPageOptions
                  },
                  "items-per-page": 100,
                  search: _vm.search,
                  loading: _vm.fetchingUserTasks,
                  headers: _vm.headers,
                  items: _vm.filteredTaskData,
                  "item-key": "tkid"
                },
                scopedSlots: _vm._u([
                  {
                    key: "top",
                    fn: function() {
                      return [
                        _c("v-text-field", {
                          staticClass: "mb-5",
                          attrs: {
                            "append-icon": "mdi-magnify",
                            label: "Filter",
                            "single-line": "",
                            "hide-details": ""
                          },
                          model: {
                            value: _vm.search,
                            callback: function($$v) {
                              _vm.search = $$v
                            },
                            expression: "search"
                          }
                        })
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "item.status",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          {
                                            attrs: {
                                              color: _vm.getTaskChipColor(
                                                item.status
                                              ),
                                              left: ""
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getTaskChipIcon(item.status)
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c("span", { staticClass: "white--text" }, [
                              _vm._v(_vm._s(_vm.taskStatusNames[item.status]))
                            ])
                          ]
                        )
                      ]
                    }
                  },
                  {
                    key: "item.operation",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "span",
                                        _vm._g(
                                          { staticClass: "text-truncate" },
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.truncateText(
                                                  item.operation,
                                                  60
                                                )
                                              )
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [_c("span", [_vm._v(_vm._s(item.operation))])]
                        )
                      ]
                    }
                  },
                  {
                    key: "item.created",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("div", { staticClass: "d-flex flex-column py-3" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("dateTimeToHuman")(item.created)) +
                              " "
                          ),
                          item.finished
                            ? _c("span", { staticClass: "caption" }, [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v("Duration: ")]
                                ),
                                _vm._v(
                                  _vm._s(
                                    _vm.calculateDuration(
                                      item.finished,
                                      item.started
                                    )
                                  ) + " "
                                )
                              ])
                            : _vm._e()
                        ])
                      ]
                    }
                  },
                  {
                    key: "item.result",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("UserTaskOutcome", {
                          attrs: { message: item.result }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm.fetchingUserTasks === false && _vm.userTasks.length === 0
            ? _c(
                "v-banner",
                { attrs: { "two-line": "" } },
                [
                  _c(
                    "v-avatar",
                    {
                      attrs: { slot: "icon", color: "white", size: "60" },
                      slot: "icon"
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: { "x-large": "", icon: "info", color: "info" }
                        },
                        [_vm._v(" info ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-column",
                      staticStyle: { "max-width": "900px" }
                    },
                    [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("No user tasks found")
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }